<template>
  <section class="subscription">
    <div class="site-margins-home" style="margin-bottom: 50px;">
      <h1>Subscriptions</h1>
      <div style="padding-bottom: 20px" class="subscription-container">
        {{ descriptions.subscription }}
      </div>
      <div class="subscription-container">
        <div
          v-for="subscription in subscriptions"
          :key="subscription.id"
          class="subscription-item"
        >
          <div class="gallery-img">
            <img :src="subscription.mainImage" />
          </div>
          <h1 v-if="subscription.from && subscription.to">
            ${{ subscription.from }} - ${{ subscription.to }} MONTHLY HAIRCUT
            SUBSCRIPTION
          </h1>
          <h1 v-else>${{ subscription.price }} MONTHLY HAIRCUT SUBSCRIPTION</h1>
          <b
            ><p>{{ subscription.name }}</p></b
          >
          <div v-html="subscription.description"></div>

          <div @click="handleSubSelection(subscription)" class="btn-solid sub">
            <p>SELECT</p>
          </div>
        </div>
      </div>
       <div v-if="descriptions.disclaimer" style="padding-top: 20px" class="subscription-container">
        <h3><strong>Disclaimer:</strong></h3> {{ descriptions.disclaimer }}
      </div>
    </div>
  </section>
</template>
<script>
import { auth, db } from "@/firebaseConfig.js";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      subscriptions: [],
      descriptions: {
        subscription: null,
        disclaimer: null
      }
    };
  },
  firestore() {
    return {
      subscriptions: db
        .collection("subscriptions")
        .where("publish", "==", true)
        .orderBy("orderNum", "asc"),
      descriptions: db.collection("attributes").doc("descriptions")
    };
  },
  methods: {
    sendEmail(user, subscription) {
      var templateParams = {
        user_name: user.name,
        user_email: user.email,
        user_phone: user.phone,
        plan_name: subscription.name,
        plan_price:
          subscription.from && subscription.to
            ? `${subscription.from} - ${subscription.to}`
            : subscription.price
      };

      return emailjs
        .send(
          "service_74gel3s",
          "template_r8etc1l",
          templateParams,
          "user_mygabaGYMWJfYfYQCnp6b"
        )
        .then(res => {
          return db
            .collection("user")
            .doc(user.id)
            .update({
              subscription: {
                plan_id: subscription.id,
                plan_price: subscription.price,
                name: subscription.name,
                subscribedAt: Date.now(),
                confirmed: false
              }
            });
        })
        .then(() => {
          Swal.mixin({
            confirmButtonText: "Add a Card",
            showCancelButton: false
          })
            .queue([
              {
                title: "Thank You for subscribing with us!",
                html: `
                <h3>To complete the set-up by adding a payment card follow these steps</h3>
                  <p>
                  1. Request a sign in code</p>
                  <p> 2. Enter your number then input sign in code delivered via text.</p>
                  <p> 3. Select "My Profile"</p>
                  <p> 4. Select "add a card"</p>
                <p><b><i>Add a credit/debit card of your choice to be charged on the 1st of each month. (Cancel anytime)</i></b></p>`
              }
            ])
            .then(result => {
              if (result.value) {
                window.open("https://squ.re/3iQ4kje", "");
              }
            });
        });
    },
    handleSubSelection(subscription) {
      let user = this.$store.state.user_doc;
      if (!user.id) {
        this.$router.push("/register");
      } else {
        this.sendEmail(user, subscription);
      }
    }
  }
};
</script>

<style scoped>
ul {
  height: fit-content !important;
}
</style>
