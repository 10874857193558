import firebase from "firebase";
import "firebase/firestore";
import store from "./store";

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STROAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(config);
const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
const fieldValue = firebase.firestore.FieldValue;
const auth = firebase.auth();
const functions = firebase.functions();
const batch = db.batch();

//When ever the user authentication state changes write the user to vuex.
firebaseApp.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch("setUser", user);
  } else {
    store.dispatch("setUser", null);
  }
});

export { db, storage, fieldValue, auth, functions, batch };
