<template>
  <section class="gallery">
    <div class="site-margins-home">
      <h1>Gallery</h1>
      <div class="gallery-container">
        <div v-for="image in gallery" class="gallery-img" :key="image.id">
          <img
            v-if="image.type && image.type.includes('image')"
            :src="image.url"
          />
          <video
            id="myvideo"
            @click="toggleControls"
            v-else-if="image.type && image.type.includes('video')"
            :src="image.url"
            controls
          ></video>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth, db } from "@/firebaseConfig.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      gallery: [],
    };
  },
  firestore() {
    return {
      gallery: db.collection("gallery").orderBy("orderNum", "asc"),
    };
  },
  methods: {
    toggleControls() {
      var video = document.getElementById("myvideo");
      if (video.hasAttribute("controls")) {
        video.removeAttribute("controls");
      } else {
        video.setAttribute("controls", "controls");
      }
    },
  },
};
</script>

