<template>
  <section class="philosophy">
    <div class="site-margins">
      <h1>Testimonials</h1>
      <div class="testimonials">
        <div
          v-for="testimonial in testimonials"
          :key="testimonial.id"
          class="testimonial-reg"
        >
          <p>"{{ testimonial.comment }}"</p>
          <div class="author">
            <p>- {{ testimonial.userName }}</p>
          </div>
        </div>
        <hr />
        <!-- <div class="testimonial-large">
          <h2>"</h2>
          <p>
            Great Care, Never a line so that's great.. warm towels depending on
            your cut i felt like i was in a spa lol.. honestly feel it's the
            best in Brooklyn
          </p>
          <h3>"</h3>
          <div class="author">
            <p>- Kelvin R.</p>
          </div>
        </div>
        <hr />
        <div class="testimonial-reg">
          <h2>"</h2>
          <p>
            For years I've been searching for a quality barber ever since my
            main one retired, so I've traveled all around Queens, Long Island,
            and the city for a new barber. Turns out I finally found him in
            Brooklyn: Mr. Gee Alexander, In Your Best Interest Barber Studio.
            Upon entering, I immediately sensed such a warm, homie atmosphere;
            the studio is clean, and professionally organized. Mr. Alexander
            himself is a very skilled barber who's been doing it for years, and
            his experience shows. He carefully made sure the cut is perfect to
            my best interest (true to the name). On top of that, he's an
            excellent conversationalist as we both shared our life stories and
            music tastes. He's a great soul who not only is a true professional
            in his craft, but will connect with you on a personal level as well.
            I will recommend him to anyone and everyone.
          </p>
          <h3>"</h3>
          <div class="author">
            <p>- Kelvin R.</p>
          </div>
        </div> -->
      </div>
    </div>

    <div class="review">
      <div class="site-margins">
        <h1>LEAVE REVIEW</h1>
        <form>
          <div class="input-black">
            <label for="name">Name</label>
            <input
              type="black"
              v-model="testimonial.userName"
              id="name"
              name="email"
            />
          </div>

          <div class="input-black">
            <label for="email">Email</label>
            <input
              type="black"
              v-model="testimonial.userEmail"
              id="email"
              name="email"
            />
          </div>
          <div class="input-black">
            <label for="review">Comment</label>
            <textarea v-model="testimonial.comment" id="review" name="review" />
          </div>
        </form>
        <div class="submit">
          <div @click="saveTestimonial" class="btn-solid-black">
            <p>SEND REVIEW</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth, db } from "@/firebaseConfig.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      currentUser: {},
      testimonial: {
        userName: null,
        userEmail: null,
        comment: null,
        createdAt: Date.now(),
        publish: true
      },
      testimonials: []
    };
  },
  firestore() {
    return {
      testimonials: db
        .collection("testimonials")
        .where("publish", "==", true)
        .orderBy("createdAt", "desc")
    };
  },
  created() {
    this.currentUser = this.$store.state.user_doc;
  },
  watch: {
    currentUser() {
      if (this.currentUser.name) {
        this.testimonial.userName = this.currentUser.name;
        this.testimonial.userEmail = this.currentUser.email;
      }
    }
  },
  methods: {
    saveTestimonial() {
      if (!this.testimonial.userName) {
        this.testimonial.userName = "Anonymous";
      }

      if (!this.testimonial.userEmail) {
        this.testimonial.userEmail = "N/A";
      }

      db.collection("testimonials")
        .doc()
        .set(this.testimonial)
        .then(() => {
          return Swal.fire(
            "Thank You!",
            "Thank you for your support and review!",
            "success"
          ).then(() => {
            this.testimonial.userName = null;
            this.testimonial.userEmail = null;
            this.testimonial.comment = null;
            this.testimonial.createdAt = Date.now();
          });
        });
    }
  }
};
</script>
