import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import firebase from 'firebase'
import GlobalDirectives from "./globalDirectives";
import { firestorePlugin } from "vuefire";
import VueTheMask from 'vue-the-mask'
import store from "./store";
import './registerServiceWorker'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(VueTheMask)
Vue.use(firestorePlugin);
Vue.use(Loading);
Vue.use(GlobalDirectives);
Vue.config.productionTip = false

let app

if (!app) {
  app = new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')

}
