<template>
  <section class="navigation">
    <div class="site-margins-nav">
      <div v-if="currentUser" class="user-wrapper">
        <div class="welcome-msg">
          <h3>Welcome Back {{ $store.state.user_doc.name.split(" ")[0] }}</h3>
        </div>
      </div>
      <div class="nav-container">
        <div class="logo">
          <div @click="$router.push('/')">
            <img src="@/assets/img/iybiwhite.png" />
          </div>
          <!-- <div class="dropdown">
            <p @click="showSchedule = !showSchedule"><b>STUDIO HOURS</b></p>

            <div v-if="showSchedule" class="dropdown-content">
              <h3>HOURS</h3>
              <div class="schedule">
                <div class="days">
                  <p>SUN</p>
                  <p>MON</p>
                  <p>TUES</p>
                  <p>WED</p>
                  <p>THURS</p>
                  <p>FRI</p>
                  <p>SAT</p>
                </div>
                <div class="hours">
                  <p>9AM-5PM</p>
                  <p>3PM-9PM</p>
                  <p>CLOSED</p>
                  <p>8AM-7PM</p>
                  <p>3PM-9PM</p>
                  <p>8AM-7PM</p>
                  <p>8AM-7PM</p>
                </div>
              </div>
              <h3>ONLINE APPOINTMENTS ONLY</h3>
            </div>
          </div> -->
        </div>
        <div @click="openMenu = !openMenu" class="menu">
          <div v-click-outside="() => openMenu = false" v-if="openMenu" class="menu-dropdown header">
            <router-link class="routerlink" to>
              <p style="font-size: 25px">X</p>
              <!-- <a class="hamburger-icon">
                <div class="line mq"></div>
                <div class="line mq"></div>
                <div class="line mq"></div>
              </a> -->
            </router-link>
            <router-link v-if="!currentUser" class="routerlink" to>
              <p @click="$router.push('/register')">Sign In</p>
            </router-link>
            <router-link class="routerlink" to="/">
              <p>HOME</p>
            </router-link>
            <router-link class="routerlink" to="/register">
              <p>BOOK</p>
            </router-link>
            <router-link class="routerlink" to="/gallery">
              <p>GALLERY</p>
            </router-link>
            <router-link class="routerlink" to="/testimonials">
              <p>TESTIMONIALS</p>
            </router-link>
            <router-link class="routerlink" to="/philosophy">
              <p>LEARN MORE</p>
            </router-link>
            <router-link class="routerlink" to="/subscription">
              <p>HAIRCUT SUBSCRIPTION</p>
            </router-link>
            <router-link class="routerlink" to="/contact">
              <p>CONTACT US</p>
            </router-link>
            <router-link v-if="currentUser" class="routerlink" to>
              <p @click="logout">LOGOUT</p>
            </router-link>
          </div>
          <!-- <p style="cursor: pointer" v-else>///</p> -->
          <a class="hamburger-icon" style="cursor: pointer" v-else>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </a>
        </div>
      </div>
    </div>

    <!-- <div id="popup1" class="pop-up-hour">
      <div class="pop-up-content">
        <h3>HOURS</h3>
        <div class="schedule">
          <div class="days">
            <p>SUN</p>
            <p>MON</p>
            <p>TUES</p>
            <p>WED</p>
            <p>THURS</p>
            <p>FRI</p>
            <p>SAT</p>
          </div>
          <div class="hours">
            <p>9AM-5PM</p>
            <p>3PM-9PM</p>
            <p>CLOSED</p>
            <p>8AM-7PM</p>
            <p>3PM-9PM</p>
            <p>8AM-7PM</p>
            <p>8AM-7PM</p>
          </div>
        </div>
        <h3>ONLINE APPOINTMENTS ONLY</h3>
      </div>
    </div> -->
  </section>
</template>

<script>
import { auth, db } from "@/firebaseConfig.js";

export default {
  data() {
    return {
      openMenu: false,
      currentUser: null,
      showSchedule: false,
    };
  },
  created() {
    auth.onAuthStateChanged((user) => {
      this.currentUser = user;
    });
  },
  methods: {
    logout() {
      return auth.signOut().then(() => {
        // this.$store.dispatch("resetState").then(() => {
        // });
      });
    },
  },
};
</script>

<style>
.hamburger-icon {
  margin: 3em auto;
  width: 2.5rem;
  display: block;
  padding-right: 20px;
}

.line {
  width: 35px;
  height: 3px;
  background: white; /* change back */
  border-radius: 2.5rem;
  margin-bottom: 7px;
  opacity: 1;
}
@media screen and (max-width: 1100px) {
  .line.mq {
    background: white;
  }
  .hamburger-icon {
    margin: 0;
    margin-left: 22px;
  }
}
</style>
