<template>
  <section class="contact">
    <div class="site-margins">
      <h1>contact us</h1>
      <div class="philosophy">
        <div class="main-img">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12106.722324953802!2d-73.895988!3d40.658971!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6caf606881c86af!2sIn%20Your%20Best%20Interest%20Barber%20Studio!5e0!3m2!1sen!2sus!4v1606674604594!5m2!1sen!2sus"
            width="100%"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>

        <div class="contact-contain">
          <div class="address">
            <p>15 Louisiana ave Brooklyn, NY 11207</p>
            {{ contact.phone }}
            <p>By Appt Only</p>
          </div>
          <div class="email">
            <p><b>EMAIL</b></p>
            <p>General Inquiries {{ contact.email }}</p>
          </div>
          <div class="email">
            <p><b>Social Media</b></p>
            <span @click="goTo(contact.social.facebook)">
              <img width="41px" src="@/assets/img/facebook.png" alt="" />
            </span>
            <span @click="goTo(contact.social.instagram)">
              <img width="36px" src="@/assets/img/ig-icon.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth, db, functions } from "@/firebaseConfig.js";
export default {
  data() {
    return {
      contact: {
        phone: "",
        email: "",
        social: {
          facebook: "",
          instagram: "",
        },
      },
    };
  },
  firestore() {
    return {
      contact: db.collection("attributes").doc("contacts"),
    };
  },
  methods: {
    goTo(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
