<template>
  <section>
    <div class="overlay"></div>
    <video
      class="frame"
      v-if="currentBackgroundImage.type == 'video/mp4'"
      :src="currentBackgroundImage.url"
      autoplay
      @play="clearInterval()"
      @ended="startTimer()"
    ></video>

    <div
      v-else
      :style="{
        backgroundImage: `url('${currentBackgroundImage.url}')`,
      }"
      class="frame"
    ></div>
  </section>
</template>

<script>
import { auth, db } from "@/firebaseConfig.js";
import moment from "moment";

export default {
  data() {
    return {
      currentBackgroundImage: {},
      currentBackgroundImageIndex: 0,
      backgroundImages: [],
      schedule: {},
      timer: 0,
    };
  },
  created() {
    this.$bind(
      "backgroundImages",
      db.collection("idle").orderBy("orderNum", "asc")
    ).then(() => {
      this.currentBackgroundImage = this.backgroundImages[0];
      this.startTimer();
    });
  },
  methods: {
    clearInterval() {
      console.log(this.timer);
      window.clearInterval(this.timer);
    },
    startTimer() {
      this.timeout = 0;
      this.timer = setInterval(() => {
        if (
          this.currentBackgroundImageIndex >
          this.backgroundImages.length - 1
        ) {
          this.currentBackgroundImageIndex = 0;
        }
        this.currentBackgroundImage = this.backgroundImages[
          this.currentBackgroundImageIndex
        ];
        this.currentBackgroundImageIndex = this.currentBackgroundImageIndex + 1;
      }, 8000);
    },
  },
};
</script>

<style scoped>
.nav-container {
  display: none;
}
</style>
