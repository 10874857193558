<template>
  <section class="registration">
    <div class="overlay">
      <div class="background-img">
        <img src="@/assets/img/bg-1.png" />
      </div>
    </div>

    <div class="site-margins-home">
      <div class="registration-container">
        <div class="login">
          <div style="margin-top: 10%;" class="iybi">
            <h1 style="font-size: 70px">In Your Best Interest</h1>
            <h2 style="color: white !important; font-size:28px">
              BARBER STUDIO
            </h2>
          </div>
          <div class="enter" v-if="hide">
            <form>
              <div class="input">
                <label for="email">Email</label>
                <input type="text" v-model="email" id="email" name="email" />
              </div>

              <div class="input">
                <label for="pword">Password</label>
                <input
                  type="password"
                  v-model="password"
                  id="pword"
                  name="pword"
                />
              </div>
            </form>
          </div>

          <div v-if="!seen" class="submit">
            <div @click="login" class="btn-solid">
              <p>SIGN IN</p>
            </div>
          </div>
        </div>

        <div class="hide">
          <div class="register">
            <div>
              <form>
                <div>
                  <label for="fname">Full Name</label>
                  <input
                    v-model="user.name"
                    type="text"
                    id="name"
                    name="name"
                  />
                </div>

                <div>
                  <label for="number">Phone Number</label>
                  <input
                    v-mask="'###-###-####'"
                    type="text"
                    id="number"
                    name="number"
                    @change="validatePhoneNumber"
                    v-model="user.phone"
                  />
                </div>

                <div>
                  <label for="email">Email</label>
                  <input
                    v-model="user.email"
                    @change="validateEmail"
                    type="text"
                    id="email"
                    name="email"
                  />
                </div>

                <div class="radio-question">
                  <label for="question"
                    >Are you in search of a private barber ?</label
                  >
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="yes"
                      v-model="user.questionaire.question1.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="no"
                      v-model="user.questionaire.question1.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question2"
                    >Are you capable of booking, canceling and/or rescheduling
                    your haircut appointments online at
                    www.iybibarberstudio.com?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="yes"
                      v-model="user.questionaire.question2.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="no"
                      v-model="user.questionaire.question2.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question3"
                    >Would a monthly subscription service where you can purchase
                    haircuts in bulk and buy your favorite hair, face and body
                    products at a discounted price be valuable to you?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="yes"
                      v-model="user.questionaire.question3.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="no"
                      v-model="user.questionaire.question3.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question4">
                    Do you usually share with close friends and family when you
                    experience a good service or product?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="yes"
                      v-model="user.questionaire.question4.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="no"
                      v-model="user.questionaire.question4.answer"
                    />
                  </div>
                </div>
                <div class="radio-question">
                  <label for="referral">Where did you hear about us?</label>
                  <div class="radio-answer">
                    <div class="option">
                      Referred By:
                      <input
                        @change="user.feedback = null"
                        type="radio"
                        value="referredBy"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Social Media:
                      <input
                        @change="user.feedback = null"
                        type="radio"
                        value="social"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Other:
                      <input
                        @change="user.feedback = null"
                        type="radio"
                        value="other"
                        v-model="showAttribute"
                      />
                    </div>
                  </div>
                  <input
                    v-if="showAttribute == 'referredBy'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                  <div class="social" v-else-if="showAttribute == 'social'">
                    <div class="option">
                      <p>Twitter</p>
                      <input
                        type="radio"
                        id="feedback"
                        value="Twitter"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Facebook</p>
                      <input
                        type="radio"
                        id="feedback"
                        value="Facebook"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Instagram</p>
                      <input
                        type="radio"
                        id="feedback"
                        value="Instagram"
                        v-model="user.feedback"
                      />
                    </div>
                  </div>
                  <input
                    v-else-if="showAttribute == 'other'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                </div>
              </form>

              <div @click="validate" class="btn">
                <p>REGISTER</p>
              </div>
            </div>
          </div>
        </div>
        <div class="show">
          <div v-if="seen" class="register">
            <div>
              <form>
                <div>
                  <label for="fname">Full Name</label>
                  <input
                    v-model="user.name"
                    type="text"
                    id="name"
                    name="name"
                  />
                </div>

                <div>
                  <label for="number">Phone Number</label>
                  <input
                    v-mask="'###-###-####'"
                    type="text"
                    id="number"
                    name="number"
                    @change="validatePhoneNumber"
                    v-model="user.phone"
                  />
                </div>

                <div>
                  <label for="email">Email</label>
                  <input
                    v-model="user.email"
                    @change="validateEmail"
                    type="text"
                    id="email"
                    name="email"
                  />
                </div>

                <div class="radio-question">
                  <label for="question"
                    >Are you in search of a private barber ?</label
                  >
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="yes"
                      v-model="user.questionaire.question1.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="no"
                      v-model="user.questionaire.question1.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question2"
                    >Are you capable of booking, canceling and/or rescheduling
                    your haircut appointments online at
                    www.iybibarberstudio.com?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="yes"
                      v-model="user.questionaire.question2.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="no"
                      v-model="user.questionaire.question2.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question3"
                    >Would a monthly subscription service where you can purchase
                    haircuts in bulk and buy your favorite hair, face and body
                    products at a discounted price be valuable to you?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="yes"
                      v-model="user.questionaire.question3.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="no"
                      v-model="user.questionaire.question3.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question4">
                    Do you usually share with close friends and family when you
                    experience a good service or product?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="yes"
                      v-model="user.questionaire.question4.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="no"
                      v-model="user.questionaire.question4.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="referral">Where did you hear about us?</label>
                  <div class="radio-answer">
                    <div class="option">
                      Referred By:
                      <input
                        type="radio"
                        @change="user.feedback = null"
                        value="referredBy"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Social Media:
                      <input
                        type="radio"
                        @change="user.feedback = null"
                        value="social"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Other:
                      <input
                        type="radio"
                        @change="user.feedback = null"
                        value="other"
                        v-model="showAttribute"
                      />
                    </div>
                  </div>
                  <input
                    v-if="showAttribute == 'referredBy'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                  <div class="social" v-else-if="showAttribute == 'social'">
                    <div class="option">
                      <p>Twitter</p>
                      <input
                        type="radio"
                        id="feedback"
                        value="Twitter"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Facebook</p>
                      <input
                        type="radio"
                        id="feedback"
                        value="Facebook"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Instagram</p>
                      <input
                        type="radio"
                        id="feedback"
                        value="Instagram"
                        v-model="user.feedback"
                      />
                    </div>
                  </div>
                  <input
                    v-else-if="showAttribute == 'other'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                </div>
              </form>

              <div @click="validate" class="btn">
                <p>REGISTER</p>
              </div>
            </div>
          </div>
        </div>
        <div class="show">
          <div class="btn-mobile" v-on:click="(seen = !seen), (hide = !hide)">
            <p>{{ !seen ? "REGISTER" : "LOG IN" }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { auth, db } from "@/firebaseConfig.js";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      showAttribute: null,
      seen: false,
      hide: true,
      feedback: null,
      password: null,
      email: null,
      user: {
        createdAt: Date.now(),
        name: null,
        phone: null,
        email: null,
        feedback: null,
        questionaire: {
          question1: {
            text: "Are you in search of a private barber ?",
            answer: null
          },
          question2: {
            text:
              "Are you capable of booking, canceling and/or rescheduling your haircut appointments online at www.iybibarberstudio.com?",
            answer: null
          },
          question3: {
            text:
              "Would a monthly subscription service where you can purchase haircuts in bulk and buy your favorite hair, face and body products at a discounted price be valuable to you?",
            answer: null
          },
          question4: {
            text:
              "Do you usually share with close friends and family when you experience a good service or product?",
            answer: null
          }
        }
      }
    };
  },
  methods: {
    login() {
      if (this.email && this.password) {
        auth
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.$router.push({ name: "Home" });
          })
          .catch(err => {
            console.error(err);
            this.password = null;
            return Swal.fire("", err.message, "warning");
          });
      } else {
        return Swal.fire(
          "",
          "Please enter both email and password.",
          "warning"
        );
      }
    },
    register() {
      let password = "iybi" + new Date().getFullYear();
      auth
        .createUserWithEmailAndPassword(this.user.email, password)
        .then(user => {
          return this.createUserData(this.user, user.user.uid);
        })
        .then(() => {
          return this.sendEmail(password);
        })
        .then(() => {
          auth.signOut();
          Swal.fire(
            "",
            "Thank you for registering with us at IBYI Barber Studio, an email will be sent to you shortly with further instructions including your loging credentials!",
            "success"
          ).then(() => {
            this.$router.push("/");
          });
        })
        .catch(error => {
          return Swal.fire("Error!", error.message, "error");
        });
    },
    createUserData(user, uid) {
      return db
        .collection("user")
        .doc(uid)
        .set(user);
    },

    sendEmail(password) {
      var templateParams = {
        to_name: this.user.name,
        to_email: this.user.email,
        password: password
      };

      return emailjs
        .send(
          "service_74gel3s",
          "template_8lkth9y",
          templateParams,
          "user_mygabaGYMWJfYfYQCnp6b"
        )
        .then(res => {
          console.log(res);
        });
    },
    validate() {
      let valid = Boolean(
        this.user.name &&
          this.user.phone &&
          this.user.email &&
          this.user.feedback
      );

      if (valid) {
        if (this.validateQuestionaire()) {
          this.register();
        } else {
          Swal.fire("", "Please answer all questions", "warning");
        }
      } else {
        Swal.fire("", "Please fill in all required fields", "warning");
      }
    },
    validatePhoneNumber(e) {
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (e.target.value.match(phoneno)) {
        return true;
      } else {
        this.user.phone = null;
        Swal.fire("", "Please enter a valid phone number", "warning");
      }
    },
    validateEmail(e) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          e.target.value
        )
      ) {
        return true;
      }
      this.user.email = null;
      return Swal.fire("", "Please enter a valid email", "warning");
    },
    validateQuestionaire() {
      let questionaire = this.user.questionaire;
      let valid = true;

      for (const key in questionaire) {
        if (!questionaire[key].answer) {
          valid = false;
        }
      }
      return valid;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (auth.currentUser) {
      window.location =
        "https://square.site/book/6E22N25E9CFZJ/iybi-barber-studio-brooklyn-ny";
    } else {
      next();
    }
  }
};
</script>

<style scoped>
.login h1 {
  color: white;
}
.iybi h2 {
  color: white !important;
}
label {
  color: white;
}
.radio-answer {
  color: white;
  border-top: 1px solid white;
  padding-top: 1%;
}
.btn-solid {
  background: white;
}
.btn-solid p {
  color: black;
}
.btn {
  border: 0.559772px solid #ffffff;
}
.btn p {
  color: white;
}
input[type="text"] {
  background-color: rgb(255, 255, 255, 0.7);
}
input[type="password"] {
  background: rgb(255, 255, 255, 0.7) !important;
}
.option {
  color: white;
}

@media screen and (max-width: 900px) {
  .btn-mobile p {
    font-family: HKGrotesk-Black;
    font-size: 20px;
    color: white;
    border-bottom: 2px solid white;
  }
}
</style>
