<template>
  <section class="philosophy">
    <div class="site-margins">
      <h1>Philosophy</h1>
      <div class="philosophy">
        <div class="main-img">
          <img
            v-if="
              philosophy.mainImage.type &&
              philosophy.mainImage.type.includes('image')
            "
            :src="philosophy.mainImage.url"
          />
          <video
            v-else-if="
              philosophy.mainImage.type &&
              philosophy.mainImage.type.includes('video')
            "
            :src="philosophy.mainImage.url"
            controls
          ></video>
        </div>
        <div v-html="philosophy.description"></div>
      </div>
    </div>
  </section>
</template>
<script>
import { db, auth } from "@/firebaseConfig.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      philosophy: {
        mainImage: {
          url:
            "https://firebasestorage.googleapis.com/v0/b/iybibarberstudio.appspot.com/o/undefined%2F1610040416822?alt=media&token=9b2566e6-1c9a-4198-b62e-e09e655064d7",
          type: "image",
        },
        description: null,
      },
    };
  },
  firestore() {
    return {
      philosophy: db.collection("philosophy").doc("nVaSKW9PSagxh2Ef0CLI"),
    };
  },
};
</script>

