import Vue from "vue";
import Router from "vue-router";
import { auth } from "@/firebaseConfig.js";

//Components
import Home from "./views/Home.vue";
import Contact from "./views/Contact.vue";
import Register from "./views/Register.vue";
import RegisterImg from "./views/Register-img.vue";
import Gallery from "./views/Gallery.vue";
import Login from "./views/Login.vue";
import Philosophy from "./views/Philosophy.vue";
import Subscriptions from "./views/Subscriptions.vue";
import Testimonials from "./views/Testimonials.vue";
import Screensaver from "./views/Screensaver.vue";

Vue.use(Router);
let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/contact",
      name: "Contact",
      component: Contact
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/gallery",
      name: "Gallery",
      component: Gallery
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/philosophy",
      name: "Philosophy",
      component: Philosophy
    },
    {
      path: "/subscription",
      name: "Subscriptions",
      component: Subscriptions
    },
    {
      path: "/register-img",
      name: "Register-img",
      component: RegisterImg
    },
    {
      path: "/testimonials",
      name: "Testimonials",
      component: Testimonials
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  let currentUser = auth.currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    next("/");
  } else {
    next();
  }
});

export default router;
