import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import { db } from "./firebaseConfig";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState({ storage: window.sessionStorage })],
    state: {
        user: {},
        user_doc: {},
    },
    getters: {
        getUser: (state) => {
            return state.user;
        },
        getUserDoc: (state) => {
            return state.user_doc;
        },
    },
    mutations: {
        ...vuexfireMutations,
        setUser(state, user) {
            state.user = user;
        },
        clearUser(state) {
            state.user = null;
            state.user_doc = null;
        },
    },
    actions: {
        setUser: firestoreAction(function (context, user) {
            if (!user) { return {} }
            return context.bindFirestoreRef(
                "user_doc",
                db.collection("user").doc(user.uid)
            )
        }),
        signOut(context) {
            context.commit("clearUser");
        },
    },
});
