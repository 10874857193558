<template>
  <section class="registration">
    <div class="site-margins-home">
      <div class="registration-container">
        <div class="login">
          <div style="margin-top: 10%" class="iybi">
            <h1 style="font-size: 70px">In Your Best Interest</h1>
            <h2 style="color: black !important; font-size: 28px">
              BARBER STUDIO
            </h2>
          </div>
          <div class="enter" v-if="hide">
            <form>
              <div class="btn-helper">
                <transition name="fade">
                  <div v-if="helper == 'email2'" class="helper">
                    <p>This is the email you registered with</p>
                  </div>
                </transition>
              </div>
              <div class="input">
                <label for="email"
                  >Email
                  <span
                    class="material-icons"
                    @click="helper = 'email2'"
                    @mouseout="helper = null"
                  >
                    info
                  </span></label
                >
                <input type="text" v-model="email" id="email" name="email" />
              </div>

              <div class="input">
                <label for="pword"
                  >Password
                  <span
                    style="
                      float: right;
                      font-size: 13px;
                      text-transform: LOWERCASE;
                    "
                    >Hint: iybi2021</span
                  ></label
                >
                <input
                  type="password"
                  v-model="password"
                  id="pword"
                  name="pword"
                />
              </div>
            </form>
          </div>

          <div v-if="!seen" class="submit">
            <div @click="login" class="btn-solid">
              <p>SIGN IN</p>
            </div>
          </div>
        </div>

        <div class="hide">
          <div class="register">
            <div>
              <form>
                <div>
                  <label for="fname"
                    >Full Name
                    <span style="font-size: 12px"
                      >(First and Last Name)</span
                    ></label
                  >
                  <input
                    v-model="user.name"
                    type="text"
                    id="name"
                    name="name"
                  />
                </div>

                <div>
                  <div class="btn-helper">
                    <transition name="fade">
                      <div v-if="helper == 'phone'" class="helper">
                        <p>This is your mobile number</p>
                      </div>
                    </transition>
                  </div>
                  <label for="number"
                    >Phone Number
                    <span
                      class="material-icons"
                      @click="helper = 'phone'"
                      @mouseout="helper = null"
                    >
                      info
                    </span>
                  </label>
                  <input
                    v-mask="'###-###-####'"
                    type="text"
                    id="number"
                    name="number"
                    @change="validatePhoneNumber"
                    v-model="user.phone"
                  />
                </div>

                <div>
                  <div class="btn-helper">
                    <transition name="fade">
                      <div v-if="helper == 'email'" class="helper">
                        <p>
                          This is the email you will be using when siging in
                        </p>
                      </div>
                    </transition>
                  </div>
                  <label for="email"
                    >Email
                    <span
                      class="material-icons"
                      @click="helper = 'email'"
                      @mouseout="helper = null"
                    >
                      info
                    </span></label
                  >
                  <input
                    v-model="user.email"
                    @change="validateEmail"
                    type="text"
                    id="email"
                    name="email"
                  />
                </div>

                <div class="radio-question">
                  <label for="question"
                    >Are you in search of a private barber ?</label
                  >
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="yes"
                      v-model="user.questionaire.question1.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="no"
                      v-model="user.questionaire.question1.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question2"
                    >Are you capable of booking, canceling and/or rescheduling
                    your haircut appointments online at
                    www.iybibarberstudio.com?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="yes"
                      v-model="user.questionaire.question2.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="no"
                      v-model="user.questionaire.question2.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question3"
                    >Would a monthly subscription service where you can purchase
                    haircuts in bulk and buy your favorite hair, face and body
                    products at a discounted price be valuable to you?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="yes"
                      v-model="user.questionaire.question3.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="no"
                      v-model="user.questionaire.question3.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question4">
                    Do you usually share with close friends and family when you
                    experience a good service or product?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="yes"
                      v-model="user.questionaire.question4.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="no"
                      v-model="user.questionaire.question4.answer"
                    />
                  </div>
                </div>
                <div class="radio-question">
                  <label for="referral">Where did you hear about us?</label>
                  <div class="radio-answer">
                    <div class="option">
                      Referred By:
                      <input
                        @change="
                          () => {
                            user.feedback = null;
                            showSocialInput = false;
                          }
                        "
                        type="radio"
                        value="referredBy"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Social Media:
                      <input
                        @change="
                          () => {
                            user.feedback = null;
                            showSocialInput = false;
                          }
                        "
                        type="radio"
                        value="social"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Other:
                      <input
                        @change="
                          () => {
                            user.feedback = null;
                            showSocialInput = false;
                          }
                        "
                        type="radio"
                        value="other"
                        v-model="showAttribute"
                      />
                    </div>
                  </div>
                  <input
                    v-if="showAttribute == 'referredBy'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                  <div class="social" v-else-if="showAttribute == 'social'">
                    <div class="option">
                      <p>Twitter</p>
                      <input
                        @click="showSocialInput = true"
                        type="radio"
                        id="feedback"
                        value="Twitter"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Facebook</p>
                      <input
                        @click="showSocialInput = true"
                        type="radio"
                        id="feedback"
                        value="Facebook"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Instagram</p>
                      <input
                        @click="showSocialInput = true"
                        type="radio"
                        id="feedback"
                        value="Instagram"
                        v-model="user.feedback"
                      />
                    </div>
                  </div>
                  <input
                    v-else-if="showAttribute == 'other'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                  <div v-if="showSocialInput">
                    <div>
                      <p>Social Tag (optional)</p>
                      <input
                        @click="showSocialInput = true"
                        type="text"
                        placeholder="@"
                        id="feedback"
                        v-model="user.socialTag"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div
                style="
                  font-family: HKGrotesk-Medium;
                  padding: 3% 0;
                  font-size: 14px;
                  font-style: italic;
                "
              >
                Click register to have your password sent out to the email you
                provided
              </div>
              <div @click="validate" class="btn">
                <p>REGISTER</p>
              </div>
            </div>
          </div>
        </div>
        <div class="show">
          <div v-if="seen" class="register">
            <div>
              <form>
                <div>
                  <label for="fname"
                    >Full Name
                    <span style="font-size: 12px"
                      >(First and Last Name)</span
                    ></label
                  >
                  <input
                    v-model="user.name"
                    type="text"
                    id="name"
                    name="name"
                  />
                </div>

                <div>
                  <div class="btn-helper">
                    <transition name="fade">
                      <div v-if="helper == 'phone'" class="helper">
                        <p>This is your mobile number</p>
                      </div>
                    </transition>
                  </div>
                  <label for="number"
                    >Phone Number
                    <span
                      class="material-icons"
                      @click="helper = 'phone'"
                      @mouseout="helper = null"
                    >
                      info
                    </span>
                  </label>
                  <input
                    v-mask="'###-###-####'"
                    type="text"
                    id="number"
                    name="number"
                    @change="validatePhoneNumber"
                    v-model="user.phone"
                  />
                </div>

                <div>
                  <div class="btn-helper">
                    <transition name="fade">
                      <div v-if="helper == 'email'" class="helper">
                        <p>
                          This is the email you will be using when siging in
                        </p>
                      </div>
                    </transition>
                  </div>
                  <label for="email"
                    >Email
                    <span
                      class="material-icons"
                      @click="helper = 'email'"
                      @mouseout="helper = null"
                    >
                      info
                    </span></label
                  >
                  <input
                    v-model="user.email"
                    @change="validateEmail"
                    type="text"
                    id="email"
                    name="email"
                  />
                </div>

                <div class="radio-question">
                  <label for="question"
                    >Are you in search of a private barber ?</label
                  >
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="yes"
                      v-model="user.questionaire.question1.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question1"
                      value="no"
                      v-model="user.questionaire.question1.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question2"
                    >Are you capable of booking, canceling and/or rescheduling
                    your haircut appointments online at
                    www.iybibarberstudio.com?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="yes"
                      v-model="user.questionaire.question2.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question2"
                      value="no"
                      v-model="user.questionaire.question2.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question3"
                    >Would a monthly subscription service where you can purchase
                    haircuts in bulk and buy your favorite hair, face and body
                    products at a discounted price be valuable to you?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="yes"
                      v-model="user.questionaire.question3.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question3"
                      value="no"
                      v-model="user.questionaire.question3.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="question4">
                    Do you usually share with close friends and family when you
                    experience a good service or product?
                  </label>
                  <div class="radio-answer">
                    Yes
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="yes"
                      v-model="user.questionaire.question4.answer"
                    />
                    No
                    <input
                      type="radio"
                      id="question"
                      name="question4"
                      value="no"
                      v-model="user.questionaire.question4.answer"
                    />
                  </div>
                </div>

                <div class="radio-question">
                  <label for="referral">Where did you hear about us?</label>
                  <div class="radio-answer">
                    <div class="option">
                      Referred By:
                      <input
                        type="radio"
                        @change="
                          () => {
                            user.feedback = null;
                            showSocialInput = false;
                          }
                        "
                        value="referredBy"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Social Media:
                      <input
                        type="radio"
                        @change="
                          () => {
                            user.feedback = null;
                            showSocialInput = false;
                          }
                        "
                        value="social"
                        v-model="showAttribute"
                      />
                    </div>
                    <div class="option">
                      Other:
                      <input
                        type="radio"
                        @change="
                          () => {
                            user.feedback = null;
                            showSocialInput = false;
                          }
                        "
                        value="other"
                        v-model="showAttribute"
                      />
                    </div>
                  </div>
                  <input
                    v-if="showAttribute == 'referredBy'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                  <div class="social" v-else-if="showAttribute == 'social'">
                    <div class="option">
                      <p>Twitter</p>
                      <input
                        @click="showSocialInput = true"
                        type="radio"
                        id="feedback"
                        value="Twitter"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Facebook</p>
                      <input
                        @click="showSocialInput = true"
                        type="radio"
                        id="feedback"
                        value="Facebook"
                        v-model="user.feedback"
                      />
                    </div>
                    <div class="option">
                      <p>Instagram</p>
                      <input
                        @click="showSocialInput = true"
                        type="radio"
                        id="feedback"
                        value="Instagram"
                        v-model="user.feedback"
                      />
                    </div>
                  </div>
                  <input
                    v-else-if="showAttribute == 'other'"
                    type="text"
                    v-model="user.feedback"
                    id="referral"
                    name="referral"
                  />
                  <div v-if="showSocialInput">
                    <div>
                      <p>Social Tag (optional)</p>
                      <input
                        @click="showSocialInput = true"
                        type="text"
                        placeholder="@"
                        id="feedback"
                        v-model="user.socialTag"
                      />
                    </div>
                  </div>
                </div>
              </form>

              <div
                style="
                  font-family: HKGrotesk-Medium;
                  padding: 3% 0;
                  font-size: 14px;
                  font-style: italic;
                "
              >
                Click register to have your password sent out to the email you
                provided
              </div>
              <div @click="validate" class="btn">
                <p>REGISTER</p>
              </div>
            </div>
          </div>
        </div>
        <div class="show">
          <div class="btn-mobile" v-on:click="(seen = !seen), (hide = !hide)">
            <p style="font-size: 15px">{{ !seen ? "REGISTER" : "LOG IN" }}</p>
          </div>
          <span v-if="!seen"
            ><div
              style="
                font-family: HKGrotesk-Medium;
                padding: 3% 0;
                font-size: 14px;
                text-decoration: underline;
              "
            >
              Don't have an account?
            </div></span
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { auth, db, functions } from "@/firebaseConfig.js";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      helper: false,
      showAttribute: null,
      seen: false,
      hide: true,
      feedback: null,
      password: null,
      email: null,
      showSocialInput: false,
      user: {
        createdAt: Date.now(),
        name: null,
        phone: null,
        email: null,
        feedback: null,
        socialTag: null,
        questionaire: {
          question1: {
            text: "Are you in search of a private barber ?",
            answer: null,
          },
          question2: {
            text:
              "Are you capable of booking, canceling and/or rescheduling your haircut appointments online at www.iybibarberstudio.com?",
            answer: null,
          },
          question3: {
            text:
              "Would a monthly subscription service where you can purchase haircuts in bulk and buy your favorite hair, face and body products at a discounted price be valuable to you?",
            answer: null,
          },
          question4: {
            text:
              "Do you usually share with close friends and family when you experience a good service or product?",
            answer: null,
          },
        },
      },
    };
  },
  methods: {
    login() {
      if (this.email && this.password) {
        auth
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.$router.push({ name: "Home" });
          })
          .catch((err) => {
            console.error(err);
            this.password = null;
            return Swal.fire({
              icon: "warning",
              html: `<h3>Oh it's just you! If you forgot your password just refer to the email you received upon registration.</h3>
              <div><sub><b>If you don't see the email please check your junk and spam mail.</b></sub></div>
            <sub>Still having trouble? email support at <a href="mailto: getsupport@iybibarberstudio.com">getsupport@iybibarberstudio.com</a> or send a DM to <a href="https://www.instagram.com/iybi_barberstudio/">@iybi_barberstudio</a> on Instagram.</sub>
            `,
              confirmButtonText: "OK",
            });
          });
      } else {
        return Swal.fire(
          "Missing Credentials",
          "Please enter both your email and password.",
          "warning"
        );
      }
    },
    register() {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      const addUser = functions.httpsCallable("addUser");
      let password = "iybi2021"
      addUser({
        email: this.user.email,
        password: password,
        name: this.user.name,
      })
        .then((res) => {
          return this.createUserData(this.user, res.data);
        })
        .then(async () => {
          return await this.sendEmail(password);
        })
        .then(() => {
          loader.hide();
          return Swal.fire({
            title: "Thank You!",
            icon: "success",
            html: `<h3>Your password has been sent to you via email</h3>
            <sub>If you don't see it check your spam or junk mail</sub>
            `,
            confirmButtonText: "Awesome!",
          }).then(() => {
            this.$router.push("/");
          });
        })
        .catch((error) => {
          loader.hide();
          return Swal.fire("Error!", error.message, "error");
        });
    },
    createUserData(user, uid) {
      return db.collection("user").doc(uid).set(user);
    },
    sendEmail(password) {
      var templateParams = {
        to_name: this.user.name,
        to_email: this.user.email,
        password: password,
      };
      return emailjs
        .send(
          "service_74gel3s",
          "template_8lkth9y",
          templateParams,
          "user_mygabaGYMWJfYfYQCnp6b"
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },
    validate() {
      let valid = Boolean(
        this.user.name &&
          this.user.phone &&
          this.user.email &&
          this.user.feedback
      );

      if (valid) {
        if (this.validateQuestionaire()) {
          this.register();
        } else {
          Swal.fire("", "Please answer all questions", "warning");
        }
      } else {
        Swal.fire("", "Please fill in all required fields", "warning");
      }
    },
    validatePhoneNumber(e) {
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (e.target.value.match(phoneno)) {
        return true;
      } else {
        this.user.phone = null;
        Swal.fire("", "Please enter a valid phone number", "warning");
      }
    },
    validateEmail(e) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          e.target.value
        )
      ) {
        return true;
      }
      this.user.email = null;
      return Swal.fire("", "Please enter a valid email", "warning");
    },
    validateQuestionaire() {
      let questionaire = this.user.questionaire;
      let valid = true;

      for (const key in questionaire) {
        if (!questionaire[key].answer) {
          valid = false;
        }
      }
      return valid;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (auth.currentUser) {
      window.location =
        "https://square.site/book/6E22N25E9CFZJ/iybi-barber-studio-brooklyn-ny";
    } else {
      next();
    }
  },
};
</script>

<style lang="scss">
.material-icons {
  font-size: 16px !important;
  color: grey !important;
}
.btn-helper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  .text_btn {
    padding: 0;
  }
  .helper {
    position: absolute;
    top: 15px;
    width: auto;
    z-index: 99;
    background-color: white;
    box-shadow: 0 0 8px rgba(128, 128, 128, 0.35);
    padding: 0 5%;
    p {
      font-size: 15px;
    }
  }
}
</style>
