<template>
  <section class="home">
    <div class="overlay"></div>
    <img :src="currentBackgroundImage.url" class="frame" />
    <div class="site-margins-homepg">
      <div class="iybi">
        <h1>In Your Best Interest</h1>
        <h2>BARBER STUDIO</h2>
      </div>
      <!-- 
      <div class="info">
        <a class="routerlink mq" href="#popup1"
          ><p
            style="
              color: white !important;
              text-decoration: underline;
              text-align: center;
              font-size: 25px;
              font-style: italic;
            "
          >
            STUDIO HOURS
          </p>
        </a>
      </div> -->
      <div class="slider-holder">
        <div id="slider">
          <ul>
            <li>
              <div class="slider-container">
                <div class="one">
                  <h2>
                    Your private and exclusive subscription based barber studio.
                  </h2>
                </div>
              </div>
            </li>

            <li>
              <div class="slider-container">
                <div class="two">
                  <h2>Powered by loyalty</h2>
                </div>
              </div>
            </li>
            <li>
              <div class="slider-container">
                <div class="three">
                  <h2>
                    Bringing efficiency, consistency and professionalism to your
                    hair cutting experience
                  </h2>
                </div>
              </div>
            </li>
            <li>
              <div class="slider-container">
                <h2>Book, Cancel, Reschedule & Shop products ALL ONLINE.</h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="mobile_nav">
        <div class="btn-row">
          <div @click="checkForUser" class="btn-white">
            <p>BOOK YOUR APPOINTMENT</p>
          </div>
          <div @click="goToShop" class="btn-white">
            <p>SHOP PRODUCTS</p>
          </div>
          <div class="btn-white">
            <router-link to="/subscription">
              <p>EXPLORE SUBSCRIPTION PLANS</p>
            </router-link>
          </div>
        </div>
        <div class="info">
          <h3>STUDIO HOURS</h3>
          <div>
            <div class="schedule-item">
              <p
                v-for="(day, k) in formatedSchedule"
                :key="k"
                class="schedule-holder"
              >
                {{ day }}: {{ k }}
              </p>
            </div>
          </div>

          <!-- <a class="routerlink mq" href="#popup1"
          ><p
            style="
              color: white !important;
              text-decoration: underline;
              text-align: center;
              font-size: 25px;
              font-style: italic;
            "
          >
            STUDIO HOURS
          </p>
        </a> -->
        </div>
      </div>
      <!-- <div id="popup1" class="overlay popup">
      <div class="popup">
        <h2>STUDIO HOURS</h2>
        <a class="close" href="#">&times;</a>
        <div class="content">
          <h3>HOURS</h3>
          <div v-for="(day, k) in schedule" :key="k" class="schedule">
            <div>
              <p>{{ k.toUpperCase() }}</p>
            </div>
            <div class="hours">
              <p v-if="!day.allDayClose">
                {{ formatTime(day.open) }}-{{ formatTime(day.close) }}
              </p>
              <p v-else>CLOSED</p>
            </div>
          </div>

          <h3>ONLINE APPOINTMENTS ONLY</h3>
        </div>
      </div>
    </div> -->
    </div>
  </section>
</template>

<script>
import { auth, db } from "@/firebaseConfig.js";
import moment from "moment";

export default {
  data() {
    return {
      currentBackgroundImage: {},
      currentBackgroundImageIndex: 0,
      backgroundImages: [],
      schedule: {},
      formatedSchedule: {},
    };
  },
  created() {
    this.$bind(
      "backgroundImages",
      db.collection("background").orderBy("orderNum", "asc")
    ).then(() => {
      this.currentBackgroundImage = this.backgroundImages[0];
      setInterval(() => {
        this.currentBackgroundImageIndex = this.currentBackgroundImageIndex + 1;
        if (
          this.currentBackgroundImageIndex >
          this.backgroundImages.length - 1
        ) {
          this.currentBackgroundImageIndex = 0;
        }
      }, 8000);
    });
    this.getSchedule();
  },
  watch: {
    currentBackgroundImageIndex() {
      this.currentBackgroundImage = this.backgroundImages[
        this.currentBackgroundImageIndex
      ];
    },
  },
  methods: {
    getSchedule() {
      db.collection("attributes")
        .doc("schedule")
        .get()
        .then((res) => {
          let data = res.data();
          let schedule = Object.fromEntries(
            Object.entries(data).sort(([, a], [, b]) => a.orderNum - b.orderNum)
          );
          this.schedule = schedule;
        })
        .then(() => {
          for (const key in this.schedule) {
            const element = this.schedule[key];
            this.formatSchedule(element, key);
          }
        });
    },
    checkForUser() {
      let user = auth.currentUser;
      if (user) {
        window.location =
          "https://square.site/book/6E22N25E9CFZJ/iybi-barber-studio-brooklyn-ny";
      } else this.$router.push("/register");
    },
    goToShop() {
      window.location = "https://www.iybi.shop/";
    },
    formatTime(hours) {
      hours = Number(hours.split(":")[0]);
      var suffix = hours >= 12 ? "PM" : "AM";
      hours = ((hours + 11) % 12) + 1 + suffix;
      return hours;
    },
    formatSchedule(date, day) {
      day = day.toUpperCase();
      let time = `${this.formatTime(date.open)}-${this.formatTime(date.close)}`;

      if (!this.formatedSchedule[time]) {
        if (date.allDayClose) {
          this.formatedSchedule["CLOSED"] = day;
        } else {
          this.formatedSchedule[time] = day;
        }
      } else {
        if (date.allDayClose) {
          this.formatedSchedule["CLOSED"] = day;
        } else {
          this.formatedSchedule[time] += "/" + day;
        }
      }
      this.$forceUpdate();
      this.formatedSchedule[time] + ":" + time;
    },
  },
};
</script>

<style>
/* @keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */

#slider,
ul {
  height: 100px;
}

#slider {
  margin: auto;
  overflow: hidden;
  /* padding: 20px;
  margin-top: 50px; */
  position: relative;
  width: 600px;
  margin-bottom: 2%;
}

#slider li {
  float: left;
  position: relative;
  width: 600px;
  display: inline-block;
  /* height: 200px; */
}

#slider ul {
  list-style: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 9000px;
  transition: left 0.2s linear;
  -moz-transition: left 0.2s linear;
  -o-transition: left 0.2s linear;
  -webkit-transition: left 0.2s linear;
  margin-left: -25px;
  font-family: open sans;
  color: #666;
}

/*** Content ***/

.slider-container {
  color: #000;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 5px;
  text-align: center;
}

.slider-container h2 {
  color: #fff;
}

/*** target hooks ****/

@-webkit-keyframes slide-animation {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  22.5% {
    opacity: 0.6;
  }
  25% {
    left: -600px;
    opacity: 1;
  }
  45% {
    left: -600px;
    opacity: 1;
  }
  47.5% {
    opacity: 0.6;
  }
  50% {
    left: -1200px;
    opacity: 1;
  }
  70% {
    left: -1200px;
    opacity: 1;
  }
  72.5% {
    opacity: 0.6;
  }
  75% {
    left: -1800px;
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  98% {
    left: -1800px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 0;
  }
}

#slider ul {
  -webkit-animation: slide-animation 20s infinite;
}

#slider ul:hover {
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

.info {
  color: white;
  margin-top: 5%;
}

.info h3 {
  font-family: "HKGrotesk-Black";
  margin: 0;
  text-decoration: underline;
}

.info p {
  margin-top: 1%;
}

.schedule-holder {
  display: flex;
  padding-right: 10px;
  flex-wrap: wrap;
  margin: 0;
}

.schedule-holder p {
  margin: 0 !important;
}

.schedule-item {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
